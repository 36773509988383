import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const FeatureHeaderImage = ({ style = {} }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "header-images/FeaturesHeaderImage.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

export default FeatureHeaderImage
