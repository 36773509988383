import React from "react"

import "./feature-block.css"

import { Heading2, Text } from "../../../components/common"

import TodayScreen from "../../../components/AppScreen/Today"
import Question from "../../../components/AppScreen/Question"
import QuestionResponse from "../../../components/AppScreen/QuestionResponse"

import MyLearningProgressImage from "../../../components/AppScreen/MyLearningProgress"
import QuestionSetProgress from "../../../components/AppScreen/QuestionSetProgress"
import QuestionSetProgressGraph from "../../../components/AppScreen/QuestionSetProgressGraph"

import CourseLibrary from "../../../components/AppScreen/CourseLibrary"
import CourseSummary from "../../../components/AppScreen/CourseSummary"
import CourseQuestion from "../../../components/AppScreen/CourseQuestion"

import CreateOwnCourse from "../../../components/AppScreen/CreateOwnCourse"
import OwnCourse from "../../../components/AppScreen/OwnCourse"
import ShareOwnCourse from "../../../components/AppScreen/ShareOwnCourse"

export default function FeatureBlock() {
  return (
    <div>
      <div style={{ backgroundColor: "#d3f8ef" }} className="feature-block">
        <div className="container mx-auto py-4 px-4">
          <div className="py-12">
            <div className="lg:flex">
              <div className="overflow-scroll md:overflow-visible">
                <div className="flex">
                  <TodayScreen />
                  <Question />
                  <QuestionResponse />
                </div>
              </div>
              <div className="flex flex-col justify-center pl-2 pr-2 pt-2 lg:pl-8 flex-1">
                <Heading2 className="text-2xl md:text-4xl py-2">
                  Daily Activities
                </Heading2>
                <Text className="py-3">
                  Complete activities each day for the courses you have chosen
                  to learn. It takes as little as two minutes a day to complete
                  an activity, meaning you’ll be able to squeeze <b>alvyApp</b>{" "}
                  into the busiest of days.
                </Text>
                <Text className="py-3">
                  Each activity consists of 10 questions, in which you self
                  assess how well you know the answer to each question.
                </Text>
                <Text className="py-3">
                  <b>alvyApp</b> works out how soon it needs to show you that
                  question again. It’s that simple. So, things you know less
                  well, you will see more often than things you know well. The
                  algorithm has been designed to encode your memory, which has
                  prioritised your learning speed as the number one feature.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feature-block">
        <div className="container mx-auto py-4 px-4">
          <div className="py-12">
            <div className="lg:flex lg:flex-row-reverse">
              <div className="overflow-scroll md:overflow-visible">
                <div className="flex">
                  <MyLearningProgressImage />
                  <QuestionSetProgress />
                  <QuestionSetProgressGraph />
                </div>
              </div>
              <div className="flex flex-col justify-center pl-2 pr-2 pt-2 lg:pr-8 flex-1">
                <Heading2 className="text-2xl md:text-4xl lg:py-2">
                  Progress Tracking
                </Heading2>
                <Text className="py-3">
                  <b>alvyApp</b> keeps track of all your daily activities, so
                  you can see how well you are progressing in each course in the
                  "My Learning" section of the app.
                </Text>
                <Text className="py-3">
                  You can see your overall proficiency of a given subject, which
                  allows you to dive into the details to see exactly which areas
                  you need to improve on.
                </Text>
                <Text className="py-3">
                  You can check on the individual questions you’re struggling
                  with, as well as the questions you’re excelling at. It breaks
                  down the overall question sets into levels too, so you know if
                  you’re a master or a novice. And all of this with real-time
                  results.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#d3f8ef" }} className="feature-block">
        <div className="container mx-auto py-4 px-4">
          <div className="py-12">
            <div className="lg:flex">
              <div className="overflow-scroll md:overflow-visible">
                <div className="flex">
                  <CourseLibrary />
                  <CourseSummary />
                  <CourseQuestion />
                </div>
              </div>
              <div className="flex flex-col justify-center pl-2 pr-2 pt-2 lg:pl-8 flex-1">
                <Heading2 className="text-2xl md:text-4xl py-2">
                  Discover alvyApp Courses
                </Heading2>
                <Text className="py-3">
                  <b>alvyApp</b> offers a set of public courses for free. Get
                  started with subjects ranging from Astronomy to Zoology.
                </Text>
                <Text className="py-3">
                  Simply add one of the public courses to your daily activities
                  and away you go.
                </Text>
                <Text className="py-3">
                  Premium content will be on it's way too soon from{" "}
                  <b>alvyApp</b>
                  's partners
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feature-block">
        <div className="container mx-auto py-4 px-4">
          <div className="py-12">
            <div className="lg:flex lg:flex-row-reverse">
              <div className="overflow-scroll md:overflow-visible">
                <div className="flex">
                  <CreateOwnCourse />
                  <OwnCourse />
                  <ShareOwnCourse />
                </div>
              </div>
              <div className="flex flex-col justify-center pl-2 pr-2 pt-2 lg:pr-8 flex-1">
                <Heading2 className="text-2xl md:text-4xl lg:py-2">
                  Create &amp; Share
                </Heading2>
                <Text className="py-3">
                  You can create your own content, for your eyes only. These
                  question sets will remain solely in your <b>alvyApp</b>.
                </Text>
                <Text className="py-3">
                  Once you’ve completed the full question set, you can archive
                  and revisit at a later date, should you choose to.
                </Text>
                <Text className="py-3">
                  After creating your own content, you might be so proud of your
                  question set and what you’ve learnt, that you want to share
                  with others. So we’ve made it easy for you to share with
                  friends and family.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
